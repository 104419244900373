import { TEXT } from 'constants/index';

import { Button } from './style';

import { BookNowEmailProps } from './constants';

const BookNowEmail = ({ title, isMobileOnly, isDesktop }: BookNowEmailProps) => {
  return (
    <Button
      isMobileOnly={isMobileOnly}
      isDesktop={isDesktop}
      href={`${TEXT.EMAIL}?subject=Enquiry: ${title}`}
    >
      Book Now via email
    </Button>
  );
};

export default BookNowEmail;
