import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-md);
`;

const spinner = keyframes`
    0% {box-shadow: 40px 0 #c3c2c9,-40px 0 #c3c2c922;background: #c3c2c9}
    33%{box-shadow: 40px 0 #c3c2c9,-40px 0 #c3c2c922;background: #c3c2c922}
    66%{box-shadow: 40px 0 #c3c2c922,-40px 0 #c3c2c9;background: #c3c2c922}
`;

export const Loader = styled.div`
  width: 24px;
  height: 24px;
  background: #c3c2c9;
  border-radius: 50%;
  box-shadow: 40px 0 #c3c2c922, -40px 0 #c3c2c9;
  animation: ${spinner} 1s infinite linear alternate;
`;
