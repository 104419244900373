import styled, { css } from 'styled-components';

import { devices } from 'utils/media-query';

export const Form = styled.form`
  display: flex;
  padding: var(--space-sm) 0 0;
  flex-direction: column;
  gap: var(--space-sm);

  @media ${devices.tablet} {
    gap: var(--space-md);
    flex-direction: row;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--space-sm);
`;

interface LabelProps {
  for?: string;
}

export const Label = styled.label<LabelProps>`
  font-family: var(--font-heading);
  border: 1px solid var(--color-light-grey);
  border-right: 0;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;

  span {
    visibility: hidden;
    width: 0px;
    height: 0px;
    overflow: hidden;
    display: block;
  }
  svg {
    margin-left: 4px;
  }
`;

export const Input = styled.input`
  font-family: var(--font-heading);
  padding: var(--space-sm);
  border: 1px solid var(--color-light-grey);
  background-color: var(--color-white-alt);
  flex: 1;
`;

export const Submit = styled.input`
  font-family: var(--font-heading);
  font-size: 1.4em;
  display: block;
  text-align: center;
  border: 2px solid;
  padding: var(--space-sm);
`;

export const Textarea = styled.textarea`
  font-family: var(--font-heading);
  padding: var(--space-sm);
  border: 1px solid var(--color-light-grey);
  background-color: var(--color-white-alt);
  flex: 1;
  min-height: 120px;
`;
export const Select = styled.select`
  font-family: var(--font-heading);
  padding: var(--space-sm);
  border: 1px solid var(--color-light-grey);
  background-color: var(--color-white-alt);
  flex: 1;
  text-transform: capitalize;
`;

export const Option = styled.option``;

interface FieldProps {
  stretch?: boolean;
}

export const Field = styled.div<FieldProps>`
  display: flex;

  ${({ stretch }) =>
    stretch &&
    css`
      flex: 1;
    `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: var(--space-lg);
  flex-direction: column;

  @media ${devices.tablet} {
    /* padding-top: var(--space-lg); */
    gap: var(--space-xl);
    flex-direction: row;
  }

  @media ${devices.laptop} {
    /* padding-top: var(--space-xxl); */
    gap: var(--space-xxl);
    flex-direction: row;
  }
`;

export const Address = styled.div`
  flex: 0 1 auto;
`;

export const Hours = styled.div`
  flex: 1 1 auto;
`;
