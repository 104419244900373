import { Table, TableTitle, TableRow, TableContent } from 'components/table/style';

const Venue = () => (
  <Table>
    <TableTitle>Venue details</TableTitle>

    <TableRow>
      <TableContent>Graceful Aura Training Academy</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>16a Mildenhall Rd</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>Liverpool</TableContent>
    </TableRow>
    <TableRow>
      <TableContent>L25&nbsp;2SR</TableContent>
    </TableRow>
  </Table>
);

export default Venue;
