import React from 'react';

import { Grid } from './style';

interface ProductGridProps {
  children: React.ReactNode | React.ReactNode[];
}

const ProductGrid = ({ children }: ProductGridProps) => {
  return <Grid>{children}</Grid>;
};

export default ProductGrid;
