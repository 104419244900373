import NavigationLinks from 'components/navigation-links';

const NavigationDesktop = () => {
  return (
    <div>
      <NavigationLinks />
    </div>
  );
};

export default NavigationDesktop;
