import { TEXT } from 'constants/index';

import { Button } from './style';
import { BookNowProps } from './constants';

const BookNow = ({ isMobileOnly, isDesktop }: BookNowProps) => {
  return (
    <Button isMobileOnly={isMobileOnly} isDesktop={isDesktop} href={TEXT.FRESHA} target="_blank">
      Book Now
    </Button>
  );
};

export default BookNow;
