import styled from 'styled-components';

import { devices } from 'utils/media-query';

import { WrapperProps } from './constants';

export const Wrapper = styled.header<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: var(--color-black);
  z-index: 999;
  transition: background-color 0.3s ease;
  background-color: ${({ noBg }) => (noBg ? 'transparent' : 'var(--color-white)')};
`;

export const Inner = styled.div`
  max-width: var(--max-width-desktop);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  padding: var(--space-md) var(--space-md);

  @media ${devices.mobileM} {
    padding: var(--space-md) var(--space-lg);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg) var(--space-xl);
  }
`;

export const Logo = styled.div`
  font-family: var(--font-heading);
`;
