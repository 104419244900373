import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { hoverSupported } from 'utils/hover-supported';

export const NavLink = styled(Link)`
  font-family: var(--font-heading);
  font-size: 1em;
  padding: 5px 10px;

  ${hoverSupported(css`
    &:hover {
      text-decoration: underline;
    }
  `)};
`;
