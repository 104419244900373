import styled from 'styled-components';

import { devices } from 'utils/media-query';

export const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-md);

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: var(--space-md);
  }
`;

export const PriceBox = styled.div`
  background-color: var(--color-white-alt);
  border-radius: 4px;
  padding: var(--space-md);
  border: 1px solid rgba(0, 0, 0, 0.05);
`;
