import { Wrapper, ImageWrapper, Content, Title } from './style';

interface EmbeddedEntryBlockProps {
  title: string;
  slug: string;
  img: string;
}

const EmbeddedEntryBlock = ({ slug, title, img }: EmbeddedEntryBlockProps) => {
  return (
    <Wrapper to={slug}>
      <ImageWrapper>
        <img src={img} alt={title} />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam blanditiis impedit, rerum
          pariatur amet debitis voluptates a, aut mollitia laudantium minima. Ea numquam nam optio
          voluptatum corrupti sint in tenetur.
        </p>
      </Content>
    </Wrapper>
  );
};

export default EmbeddedEntryBlock;
