import Content from 'components/content';

import {
  FullScreenWrapper,
  FullScreenInner,
  FullScreenTextWrapper,
  Title,
  Subtitle,
} from './style';

const Home = () => {
  return (
    <>
      <FullScreenWrapper>
        <FullScreenInner>
          <FullScreenTextWrapper>
            <Title>Graceful Aura</Title>
            <Subtitle>Spa treatments & Training Academy</Subtitle>
          </FullScreenTextWrapper>
        </FullScreenInner>
      </FullScreenWrapper>
      <Content>
        <h2>About us</h2>

        <p>
          Welcome to Graceful Aura. My name is Jade Ryan, owner of Graceful Aura. Graceful Aura is a
          combination of Holistic Therapies provided in our Liverpool Clinic and mobile services in
          Liverpool and also a training academy for Holistic Therapies.
        </p>

        <p>Therapies we offer are:</p>
        <ul>
          <li>
            Massage Therapy, including Full Body Massage, Indian Head Massage, Foot &amp; Leg
            Massage
          </li>
          <li>Hot Stone Massage</li>
          <li>Oncology Massage</li>
          <li>Pregnancy Massage</li>
          <li>Cupping Therapy, including scalp Massage</li>
          <li>Manual Lymphatic Drainage (MLD)</li>
          <li>Post Operative Manual Lymphatic Drainage, including K-Taping.</li>
          <li>Reflexology</li>
          <li>Hopi Ear Candling, including scalp Massage &amp; Facial Drainage</li>
        </ul>

        <p>
          I (Jade) provide treatments at my clinic in Liverpool. (16a Mildenhall Rd, Liverpool
          L25&nbsp;2SR).
        </p>
        <p>
          Caelie is mobile in Liverpool, providing treatments in the comfort of your own home -
          bringing the Spa to YOU! Our services include everything required for a relaxing treatment
          at home, including the music. All you have to do is sit back and relax.
        </p>

        <h2>Holistic Training Courses</h2>

        <p>I offer many Holistic Training Courses in Liverpool.</p>
        <p>Face to Face courses:</p>
        <ul>
          <li>Diploma in Swedish Body Massage</li>
          <li>Diploma in Pregnancy Massage</li>
          <li>Diploma in Oncology Massage</li>
          <li>Diploma in Indian Head Massage</li>
          <li>Diploma in Hot Stone Massage</li>
          <li>Diploma in Reflexology</li>
          <li>Diploma in Hopi Ear Candling</li>
          <li>Diploma in Chinese Cupping Therapy</li>
          <li>Diploma in Classic Manual Lymphatic Drainage & Post Operative Drainage</li>
        </ul>

        <h2>Online Course</h2>
        <ul>
          <li>Anatomy &amp; Physiology Diploma</li>
        </ul>

        <p>
          If you have any questions, please don&#39;t hesitate to contact us using the enquiry form
          or reach out on social media. Hopefully see you soon!
        </p>

        <h3 className="text-center">Jade</h3>
        {/*  */}
      </Content>
    </>
  );
};

export default Home;
