import { useState, useEffect, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { fetchTags } from 'redux/features/pagesSlice';
import { getApp } from 'redux/selectors/app';

import Header from 'components/header';
import Loading from 'components/loading';

import HomePage from 'views/home';
import ContactPage from 'views/contact';
import PricesPage from 'views/prices';
import Treatments from 'views/treatments';
import Treatment from 'views/treatment';
import Training from 'views/training';
import TrainingSingle from 'views/training-single';
import Faq from 'views/faq';
import NotFound from 'views/404';

import { GlobalStyles, Wrapper, Main } from './style';

const Footer = lazy(() => import('components/footer'));
const Social = lazy(() => import('components/social'));

function App() {
  const [isTop, setIsTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const { loading } = useSelector(getApp);

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(fetchTags());
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.pageYOffset;
      if (scrollY === 0 && !isTop) {
        setIsTop(true);
      } else if (scrollY !== 0 && isTop) {
        setIsTop(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isTop]);

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isTreatment = location.pathname.includes('treatments');
  const isTraining = location.pathname.includes('training');

  return (
    <>
      <GlobalStyles />

      <Wrapper data-testid="app-wrapper">
        <Header
          noBg={isTop}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isTreatment={isTreatment}
          isTraining={isTraining}
        />
        <Main>
          <Routes>
            <Route path={`/`} element={<HomePage />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/treatments" element={<Treatments />} />
            <Route path="/treatments/:id" element={<Treatment />} />
            <Route path="/prices" element={<PricesPage />} />
            <Route path="/training" element={<Training />} />
            <Route path="/training/:id" element={<TrainingSingle />} />
            <Route path={`/contact`} element={<ContactPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Main>

        <Social />
        <Footer />
      </Wrapper>

      {loading && <Loading />}
    </>
  );
}

export default App;
