import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { filterPageById } from 'redux/selectors/page';
import { fetchPageById } from 'redux/features/pagesSlice';

import Banner from 'components/banner';
import PriceTable from 'components/price-table';
import Content from 'components/content';
import useRichText from 'components/rich-text';
import BookNowEmail from 'components/book-now-email';

import Venue from 'components/venue';
import BackTo from 'components/back-to';

import {
  ContentLayoutSidebar,
  ContentSidebar,
  ContentSidebarItem,
  ImageWrapper,
  ContentColumn,
} from 'components/content/style';

interface TreatmentInterface {
  time: string;
  price: string;
}

interface PageProps {
  title?: string;
  hero?: any;
  content1?: any;
  treatments?: TreatmentInterface[];
}

const Treatment = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = params;

  const [page, setPage] = useState<PageProps>();

  const getPage = useSelector(filterPageById(id));

  useEffect(() => {
    if (getPage) {
      setPage(getPage);
    } else {
      dispatch(fetchPageById(id));
    }
  }, [dispatch, getPage, id, page]);

  const { title, hero, content1, treatments } = page || {};

  const hasContent1 = useRichText(content1);

  if (!id) {
    return <Banner>No treatment found</Banner>;
  }
  let imgSrc;
  let imgtitle;

  if (page && hero) {
    imgSrc = hero[0]?.fields?.file?.url;
    imgtitle = hero[0]?.fields?.title;
  }

  return (
    <>
      {page && (
        <>
          <Banner>{title}</Banner>

          <Content>
            <ContentLayoutSidebar>
              <ContentColumn>
                <BookNowEmail title={title} isMobileOnly />

                {imgSrc && (
                  <ImageWrapper>
                    <img src={imgSrc} alt={imgtitle} />
                  </ImageWrapper>
                )}

                {hasContent1}
              </ContentColumn>
              <ContentSidebar>
                <BookNowEmail title={title} isDesktop />
                <ContentSidebarItem>
                  <h2>Want to make booking?</h2>
                  <p>
                    We will respond to arrange a date and confirm the payment details to purchase
                    this course.
                  </p>
                </ContentSidebarItem>

                {treatments && (
                  <ContentSidebarItem>
                    <PriceTable list={treatments} />
                  </ContentSidebarItem>
                )}
                <ContentSidebarItem>
                  <Venue />
                </ContentSidebarItem>
              </ContentSidebar>
            </ContentLayoutSidebar>
          </Content>

          <BackTo preText="Back to " text="Training" to="/training" />
        </>
      )}
    </>
  );
};

export default Treatment;
