// import { useRef, useState, useCallback } from 'react';
// import emailjs from '@emailjs/browser';
// import { TYPE, EMAIL_CONFIG } from 'constants/index';

// import { CiMail, CiPhone, CiUser, CiSquareChevDown } from 'react-icons/ci';

import Banner from 'components/banner';
import Content from 'components/content';
import OpeningHours from 'components/opening-hours';

import {
  // Form,
  // Label,
  // Column,
  // Input,
  // Textarea,
  // Field,
  // Select,
  // Option,
  // Submit,
  Grid,
  Address,
  Hours,
} from './style';

const ContactPage = () => {
  // const form = useRef();

  // const [selectedOption, setSelectedOption] = useState(null);

  // const handleChange = (e) => {
  //   setSelectedOption(e.target.value);
  // };

  // const emailConfig = useCallback(() => {
  //   return selectedOption && EMAIL_CONFIG[selectedOption];
  // }, [selectedOption]);

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   console.log(emailConfig(), { e });

  //   emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
  //     (result) => {
  //       console.log(result.text);
  //     },
  //     (error) => {
  //       console.log(error.text);
  //     },
  //   );
  // };

  return (
    <>
      <Banner>Contact</Banner>
      <Content>
        {/* <Form ref={form} onSubmit={sendEmail}>
          <Column>
            <Field>
              <Label htmlFor="type">
                <CiSquareChevDown />
                <span>Type</span>
              </Label>
              <Select name="type" id="type" value={selectedOption} onChange={handleChange} required>
                <Option value="" disabled selected hidden>
                  Select enquiry type
                </Option>
                <Option value={TYPE.SPA}>{TYPE.SPA.toLocaleLowerCase()}</Option>
                <Option value={TYPE.TRAINING}>{TYPE.TRAINING.toLocaleLowerCase()}</Option>
              </Select>
            </Field>

            <Field>
              <Label htmlFor="name">
                <CiUser />
                <span>Full name</span>
              </Label>
              <Input type="text" name="name" id="name" placeholder="Full name" required />
            </Field>

            <input type="hidden" name="last_name" />

            <Field>
              <Label htmlFor="email">
                <CiMail /> <span>Email</span>
              </Label>
              <Input type="email" name="email" id="email" placeholder="Email" required />
            </Field>

            <Field>
              <Label htmlFor="phone">
                <CiPhone /> <span>Phone</span>
              </Label>
              <Input type="phone" name="phone" id="phone" placeholder="Phone" required />
            </Field>
          </Column>

          <Column>
            <Field stretch>
              <Textarea name="message" id="message" placeholder="Message" required />
            </Field>
            <Submit type="submit" value="Send" />
          </Column>
        </Form> */}

        <Grid>
          <Address>
            <h2>Address</h2>
            <p>Graceful Aura</p>
            <p>16a Mildenhall Rd</p>
            <p>Liverpool</p>
            <p>L25&nbsp;2SR</p>
          </Address>

          <Hours>
            <OpeningHours />
          </Hours>
        </Grid>
        <h2>Get in touch</h2>
        <p>Please check our FAQ page first to see if we have already answered your query</p>
      </Content>
    </>
  );
};

export default ContactPage;
