import styled from 'styled-components';

export const Details = styled.details`
  box-sizing: border-box;
  margin-bottom: var(--space-md);
  border: 1px solid var(--color-light-grey);

  &[open] > summary:before {
    transform: rotate(90deg);
  }
`;

export const Summary = styled.summary`
  outline: none;
  padding: var(--space-md);
  display: block;
  color: var(--color-black);
  position: relative;
  cursor: pointer;
  font-family: var(--font-heading);

  &::before {
    content: '';
    border-width: 0.4rem;
    border-style: solid;
    border-color: transparent transparent transparent #000;
    position: absolute;
    top: calc(50% - 0.2rem);
    right: var(--space-sm);
    transform: rotate(0);
    transform-origin: 0.2rem 50%;
    transition: 0.25s transform ease;
  }

  ::-webkit-details-marker {
    display: none;
  }

  h2 {
    margin: 0;
    border: none;
  }
`;

export const Content = styled.div`
  padding: var(--space-md);
  background: var(--color-white-alt);
  border-top: 1px solid var(--color-light-grey);
`;
