import { TEXT } from 'constants/index';

import { Wrapper, Seporator, Top, Bottom } from './style';

interface LogoProps {
  isTreatment?: boolean;
  isTraining?: boolean;
}

const Logo = ({ isTreatment, isTraining }: LogoProps) => {
  return (
    <Wrapper>
      <Seporator isTop />
      <Top>{TEXT.COMPANY}</Top>

      {isTreatment && <Bottom>{TEXT.TREATMENTS}</Bottom>}
      {isTraining && <Bottom>{TEXT.TRAINING}</Bottom>}
      <Seporator />
    </Wrapper>
  );
};

export default Logo;
