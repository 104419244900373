import { ExternalLink } from './style';
import { ExternalNavigationLinkProps } from './constants';

const ExternalNavigationLink = ({ children, href, target }: ExternalNavigationLinkProps) => {
  return (
    <ExternalLink href={href} target={target}>
      {children}
    </ExternalLink>
  );
};

export default ExternalNavigationLink;
