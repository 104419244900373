import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { fetchPageByTag } from 'redux/features/pagesSlice';
import { filterPageBySlug } from 'redux/selectors/page';

import Banner from 'components/banner';
import Content from 'components/content';
import ProductGrid from 'components/product-grid';
import ProductGridItem from 'components/product-grid-item';

interface PageProps {
  id: string;
  slug: string;
  title: string;
  hero: any;
}

const Treatments = () => {
  const dispatch = useDispatch<AppDispatch>();

  const TREATMENT = 'treatment';

  const getPages = useSelector(filterPageBySlug(TREATMENT));

  useEffect(() => {
    dispatch(fetchPageByTag(TREATMENT));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner>Treatments</Banner>
      <Content>
        <ProductGrid>
          {getPages &&
            getPages.map((page: PageProps) => {
              const { id, slug, title, hero } = page;

              const backgroundImg = hero && hero[0]?.fields?.file?.url;

              return (
                <ProductGridItem key={id} to={slug} imgSrc={backgroundImg} imgAlt={title}>
                  {title}
                </ProductGridItem>
              );
            })}
        </ProductGrid>
      </Content>
    </>
  );
};

export default Treatments;
