import styled from 'styled-components';

export const Wrapper = styled.div`
  font-family: var(--font-heading);
  text-align: center;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface SeporatorProps {
  isTop?: boolean;
}

export const Seporator = styled.div<SeporatorProps>`
  height: 1px;
  width: 100%;
  background: var(--color-black);

  ${({ isTop }) => (isTop ? 'margin-bottom: 1px' : 'margin-top: 1px')};
`;

export const Top = styled.h3`
  white-space: nowrap;
  font-size: 1.35em;
`;

export const Bottom = styled.h4`
  white-space: nowrap;
  font-size: 1em;
`;
