import { Wrapper, Inner, Text, Highlight } from './style';

interface BackToProps {
  preText?: string;
  text: string;
  to: string;
}
const BackTo = ({ preText, text, to }: BackToProps) => {
  return (
    <Wrapper to={to}>
      <Inner>
        <Text>
          {preText} {text && <Highlight>{text}</Highlight>}
        </Text>
      </Inner>
    </Wrapper>
  );
};

export default BackTo;
