import NavigationLink from 'components/navigation-link';
import ExternalNavigationLink from 'components/navigation-link-external';

import { TEXT } from 'constants/index';

const NavigationLinks = () => {
  return (
    <>
      <NavigationLink to={'/'}>Home</NavigationLink>
      <NavigationLink to={'/treatments'}>Treatments</NavigationLink>
      <NavigationLink to={'/training'}>Training</NavigationLink>
      <NavigationLink to={'/prices'}>Prices</NavigationLink>
      <NavigationLink to={'/faq'}>Faq</NavigationLink>
      <NavigationLink to={'/contact'}>Contact</NavigationLink>
      <ExternalNavigationLink href={TEXT.FRESHA} target="_blank">
        Book now
      </ExternalNavigationLink>
    </>
  );
};

export default NavigationLinks;
