import { ContentProps } from './constants';
import { Wrapper, Inner } from './style';

const Content = ({ children }: ContentProps) => {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  );
};

export default Content;
