import styled from 'styled-components';

import { devices } from 'utils/media-query';

import WhiteMarble from 'images/white-marble.jpg';

export const Wrapper = styled.section`
  display: flex;
  align-items: end;

  height: 33vh;
  min-height: 235px;
  max-height: 450px;
  background-color: var(--color-white-alt);
  background-image: url(${WhiteMarble});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;

  @media ${devices.mobileM} {
  }
  @media ${devices.laptop} {
  }
`;

export const Inner = styled.div`
  max-width: var(--max-width-desktop);
  display: flex;
  flex: 1;
  margin: 0 auto;

  padding: var(--space-lg) var(--space-md);

  @media ${devices.mobileM} {
    padding: var(--space-lg) var(--space-lg);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg) var(--space-xl);
  }
`;

export const Title = styled.h1`
  color: var(--color-black);
`;
