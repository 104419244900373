import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { devices } from 'utils/media-query';

export const Wrapper = styled(Link)`
  display: block;
  text-align: center;
  background: var(--color-light-grey);
  padding: var(--space-lg) var(--space-md);

  @media ${devices.mobileM} {
    padding: var(--space-lg) var(--space-lg);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg) var(--space-xl);
  }
`;

export const Inner = styled.div`
  max-width: var(--max-width-desktop);
  margin: 0 auto;
`;

export const Text = styled.p`
  font-family: var(--font-heading);
`;

export const Highlight = styled.span`
  font-family: inherit;
  border-bottom: 1px solid var(--color-black);
`;
