import { Table, TableTitle, TableRow, TableContent, TableBackground } from 'components/table/style';

const OpeningHours = () => {
  return (
    <>
      <TableTitle>Opening hours</TableTitle>
      <TableBackground>
        <Table>
          <h3>Jade – clinic based</h3>
          <TableRow>
            <TableContent>Monday</TableContent>
            <TableContent>9:30am - 2:30pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Tuesday</TableContent>
            <TableContent>9:30am - 2:30pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Wednesday</TableContent>
            <TableContent>9:30am - 2:30pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Thursday</TableContent>
            <TableContent>9:30am - 2:30pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Friday</TableContent>
            <TableContent>9:30am - 2:30pm</TableContent>
          </TableRow>

          <h3>Caelie - mobile</h3>

          <TableRow>
            <TableContent>Tuesday</TableContent>
            <TableContent>5pm - 8pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Thursday</TableContent>
            <TableContent>5pm - 8pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Saturday</TableContent>
            <TableContent>8am - 1:30pm</TableContent>
          </TableRow>

          <h3>Once a month</h3>
          <TableRow>
            <TableContent>Friday</TableContent>
            <TableContent>5pm - 8pm</TableContent>
          </TableRow>
          <TableRow>
            <TableContent>Sunday</TableContent>
            <TableContent>8am - 1:30pm</TableContent>
          </TableRow>
        </Table>
      </TableBackground>
    </>
  );
};

export default OpeningHours;
