import Banner from 'components/banner';
import Content from 'components/content';

import { TableRow, TableContent } from 'components/table/style';

import TreatmentPrices from './treatments.json';
import TrainingPrices from './training.json';

import { PriceGrid, PriceBox } from './styles';

const Prices = () => {
  return (
    <>
      <Banner>Prices</Banner>

      <Content>
        <h2>Treatments</h2>
        <PriceGrid>
          {TreatmentPrices.map(({ title, treatments }) => (
            <PriceBox key={title}>
              {title && <h3>{title}</h3>}
              {treatments.map(({ time, price }) => (
                <TableRow key={`${title}-${time}`}>
                  <TableContent>{time} minutes</TableContent>
                  <TableContent>£{price}</TableContent>
                </TableRow>
              ))}
            </PriceBox>
          ))}
        </PriceGrid>

        <h2>Training</h2>
        <PriceGrid>
          {TrainingPrices.map(({ title, treatments }) => (
            <PriceBox key={title}>
              {title && <h3>{title}</h3>}
              {treatments.map(({ price, notes }) => (
                <TableRow key={`${title}`}>
                  {notes && <TableContent>{notes}</TableContent>}
                  {price && <TableContent>£{price}</TableContent>}
                </TableRow>
              ))}
            </PriceBox>
          ))}
        </PriceGrid>
      </Content>
    </>
  );
};

export default Prices;
