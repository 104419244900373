import {
  GridItem,
  GridItemImg,
  GridItemContent,
  GridItemOverlay,
  GridItemContentTitle,
} from './style';

interface ProductGridItemProps {
  children: React.ReactNode;
  to: any;
  imgSrc?: string;
  imgAlt?: string;
}

const ProductGridItem = ({ children, to, imgSrc, imgAlt }: ProductGridItemProps) => {
  return (
    <GridItem to={to}>
      <GridItemImg src={imgSrc} alt={imgAlt} />
      <GridItemOverlay />
      <GridItemContent>
        <GridItemContentTitle>{children}</GridItemContentTitle>
      </GridItemContent>
    </GridItem>
  );
};

export default ProductGridItem;
