import styled from 'styled-components';

import { devices } from 'utils/media-query';

import WhiteMarble from 'images/white-marble.jpg';

export const FullScreenWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: var(--color-white-alt);
  background-image: url(${WhiteMarble});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
`;
export const FullScreenInner = styled.div`
  display: flex;
  height: 100%;
  max-width: var(--max-width-desktop);
  margin: 0 auto;
  position: relative;
`;

export const FullScreenTextWrapper = styled.div`
  display: flex;
  height: 100%;
  flex: 0 1 85%;
  flex-direction: column;
  justify-content: center;
  color: var(--color-black);
  z-index: 1;

  padding: var(--space-sm) var(--space-md);

  @media ${devices.mobileM} {
    flex: 0 1 80%;
    padding: var(--space-md) var(--space-lg);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg) var(--space-xl);
  }
  span {
    display: block;
    font-family: inherit;
  }
`;

export const Title = styled.h1`
  margin-bottom: var(--space-xs);
  position: relative;
  font-size: 1.75rem;

  @media ${devices.mobileM} {
    font-size: 2em;
  }
  @media ${devices.laptop} {
    margin-bottom: var(--space-sm);
    font-size: 2.5rem;
  }
`;

export const Subtitle = styled.h1`
  font-size: 1.25rem;

  @media ${devices.mobileM} {
    font-size: 1.5em;
  }
  @media ${devices.laptop} {
    font-size: 1.75rem;
  }
`;
