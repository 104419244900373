import { Details as Wrapper, Summary, Content } from './style';

interface DetailsProps {
  title: string;
  content: string | JSX.Element | JSX.Element[];
}

const Details = ({ title, content }: DetailsProps) => {
  return (
    <Wrapper>
      <Summary>
        <h2>{title}</h2>
      </Summary>
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default Details;
