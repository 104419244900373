import styled from 'styled-components';

import { devices } from 'utils/media-query';

import { NavLink } from 'components/navigation-link/style';
import { ExternalLink } from 'components/navigation-link-external/style';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  padding: var(--space-md) var(--space-md);

  @media ${devices.mobileM} {
    padding: var(--space-md) var(--space-lg);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg) var(--space-xl);
  }

  ${NavLink}, ${ExternalLink} {
    display: block;
    padding: 0.6em 0;
    font-size: 1.2em;
    border-bottom: 1px dashed var(--color-light-grey);
    &:last-child {
      border: none;
    }
  }
`;

export const Inner = styled.div`
  max-width: var(--max-width-desktop);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;

export const NavigationWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - var(--space-md));
  padding-bottom: var(--space-md);
`;

export const CloseIcon = styled.h1``;
