import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ContentfulClient from 'hooks/useContentful';

import { setLoading, clearLoading, setError } from './appSlice';

export const initialState = {
  title: null,
  content: [],
};

export const fetchFaq = createAsyncThunk('faq/fetchFaq', async (_, { dispatch }) => {
  try {
    dispatch(setLoading());
    const entries = await ContentfulClient.getEntries({
      content_type: 'list',
      select: 'fields',
    });

    const sanitizedEntries = entries.items.map((item) => {
      return {
        ...item.fields,
      };
    });

    dispatch(clearLoading());
    return sanitizedEntries;
  } catch (error) {
    console.log(`Error fetching ${error}`);
    dispatch(clearLoading());
    dispatch(setError(error.message));
    throw error;
  }
});

const faqSlice = createSlice({
  name: 'faq',
  initialState: initialState,
  extraReducers: {
    [fetchFaq.fulfilled]: (state, action) => {
      const { title, titleContent } = action.payload[0];

      state.title = title;
      state.content = titleContent;
    },
  },
});

export default faqSlice.reducer;
