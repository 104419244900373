import { Wrapper } from './style';

interface EmbeddedEntryInlineProps {
  title: string;
  slug: string;
}

const EmbeddedEntryInline = ({ slug, title }: EmbeddedEntryInlineProps) => {
  return (
    <Wrapper to={slug}>
      View <span>{title}</span>
    </Wrapper>
  );
};

export default EmbeddedEntryInline;
