import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
  border-radius: 4px;
  display: inline-block;
  font-family: var(--font-heading);
  background: #f0f0f0;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 180px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
export const Content = styled.div`
  padding: var(--space-sm);
`;

export const Title = styled.h3``;
