import styled, { css } from 'styled-components';

import { hoverSupported } from 'utils/hover-supported';

export const ExternalLink = styled.a`
  font-family: var(--font-heading);
  font-size: 1em;
  padding: 5px 10px;

  ${hoverSupported(css`
    &:hover {
      text-decoration: underline;
    }
  `)};
`;
