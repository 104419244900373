// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ContentfulClient from 'hooks/useContentful';

import { setLoading, clearLoading, setError, clearError } from './appSlice';

export const initialState = {
  pages: [],
  page: [],
  tags: [],
};

export const fetchPages = createAsyncThunk('pages/fetchPages', async (_, { dispatch }) => {
  dispatch(clearError());
  try {
    dispatch(setLoading());
    const entries = await ContentfulClient.getEntries({
      content_type: 'page',
      select: 'fields',
      order: 'sys.createdAt',
    });

    const sanitizedEntries = entries.items.map((item) => {
      return {
        ...item.fields,
      };
    });
    dispatch(clearLoading());
    return sanitizedEntries;
  } catch (error) {
    console.log(`Error fetching ${error}`);
    dispatch(setError(error.message));
    dispatch(clearLoading());
    throw error;
  }
});

export const fetchPageById = createAsyncThunk(
  'pages/fetchPageById',
  async (pageId: string | undefined, { dispatch }) => {
    try {
      dispatch(setLoading());
      const entries = await ContentfulClient.getEntries({
        'fields.id': pageId,
        content_type: 'page',
        select: 'fields',
        order: 'sys.createdAt',
      });

      const sanitizedEntries = entries.items.map((item) => {
        return {
          ...item.fields,
        };
      });
      dispatch(clearLoading());
      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching ${error}`);
      dispatch(setError(error.message));
      dispatch(clearLoading());
      throw error;
    }
  },
);

export const fetchTags = createAsyncThunk('pages/fetchTags', async (_, { dispatch }) => {
  try {
    dispatch(setLoading());
    const tags = await ContentfulClient.getTags();

    const sanitizedEntries = tags.items.map((item) => {
      const name = item.name;

      return name;
    });

    dispatch(clearLoading());
    return sanitizedEntries;
  } catch (error) {
    console.log(`Error fetching ${error}`);
    dispatch(setError(error.message));
    dispatch(clearLoading());
    throw error;
  }
});

export const fetchPageByTag = createAsyncThunk(
  'pages/fetchPageByTag',
  async (tagName: string, { dispatch }) => {
    try {
      dispatch(setLoading());
      const entries = await ContentfulClient.getEntries({
        'metadata.tags.sys.id[in]': tagName,
        content_type: 'page',
        select: 'fields',
        order: 'sys.createdAt',
      });

      const sanitizedEntries = entries.items.map((item) => {
        return {
          ...item.fields,
        };
      });

      dispatch(clearLoading());
      return sanitizedEntries;
    } catch (error) {
      console.log(`Error fetching ${error}`);
      dispatch(setError(error.message));
      dispatch(clearLoading());
      throw error;
    }
  },
);

const pagesSlice = createSlice({
  name: 'pages',
  initialState: initialState,
  extraReducers: {
    [fetchPages.fulfilled]: (state, action) => {
      const newPages = action.payload.filter((newPage) => {
        return !state.pages.some((page) => page.id === newPage.id);
      });
      state.pages = [...state.pages, ...newPages];
    },
    [fetchPages.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchPageById.pending]: (state) => {
      state.loading = true;
    },
    [fetchPageById.fulfilled]: (state, action) => {
      state.loading = false;
      const newPages = action.payload.filter((newPage) => {
        return !state.pages.some((page) => page.id === newPage.id);
      });
      state.pages = [...state.pages, ...newPages];
    },
    [fetchPageById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchPageByTag.pending]: (state) => {
      state.loading = true;
    },
    [fetchPageByTag.fulfilled]: (state, action) => {
      state.loading = false;
      const newPages = action.payload.filter((newPage) => {
        return !state.pages.some((page) => page.id === newPage.id);
      });
      state.pages = [...state.pages, ...newPages];
    },
    [fetchPageByTag.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchTags.pending]: (state) => {
      state.loading = true;
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    },
    [fetchTags.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default pagesSlice.reducer;
