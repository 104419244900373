import styled from 'styled-components';

import { devices } from 'utils/media-query';

import { BookNowEmailProps } from './constants';

export const Button = styled.a<BookNowEmailProps>`
  font-family: var(--font-heading);
  font-size: 1.4em;
  display: block;
  text-align: center;
  border: 2px solid;
  padding: var(--space-sm);

  order: ${({ isDesktop }) => isDesktop && 10};

  @media ${devices.tablet} {
    display: ${({ isMobileOnly }) => isMobileOnly && 'none'};
    order: ${({ isDesktop }) => isDesktop && 'initial'};
  }
`;
