import { Link } from 'react-router-dom';

import NavigationLinks from 'components/navigation-links';
import Logo from 'components/logo';

import { Wrapper, CloseIcon, Inner, NavigationWrapper } from './style';

export interface NavigationLinkProps {
  closeMenu: (close: boolean) => void;
  isTreatment?: boolean;
  isTraining?: boolean;
}

const NavigationMobile = ({ closeMenu, isTreatment, isTraining }: NavigationLinkProps) => {
  return (
    <Wrapper>
      <Inner>
        <Link to={'/'}>
          <Logo isTreatment={isTreatment} isTraining={isTraining} />
        </Link>
        <CloseIcon onClick={() => closeMenu(false)}>X</CloseIcon>
      </Inner>
      <NavigationWrapper>
        <NavigationLinks />
      </NavigationWrapper>
    </Wrapper>
  );
};

export default NavigationMobile;
