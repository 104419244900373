import { memo } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'components/logo';
import Navigation from 'components/navigation';

import { HeaderProps } from './constants';

import { Wrapper, Inner } from './style';

const Header = ({ noBg, isMenuOpen, setIsMenuOpen, isTreatment, isTraining }: HeaderProps) => (
  <Wrapper noBg={noBg}>
    <Inner>
      <Link to={'/'}>
        <Logo isTreatment={isTreatment} isTraining={isTraining} />
      </Link>
      <Navigation
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isTreatment={isTreatment}
        isTraining={isTraining}
      />
    </Inner>
  </Wrapper>
);

export default memo(Header);
