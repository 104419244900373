import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { AppDispatch } from 'redux/store';
import { fetchFaq } from 'redux/features/faqSlice';
import { getFaq } from 'redux/selectors/faq';

import Banner from 'components/banner';
import Content from 'components/content';
import OpeningHours from 'components/opening-hours';
import BookNow from 'components/book-now';
import Details from 'components/details';

import { ContentLayoutSidebar, ContentSidebar, ContentSidebarItem } from 'components/content/style';

interface FaqInterface {
  title: string;
  content: string;
}

const FAQ = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { title, content } = useSelector(getFaq);

  useEffect(() => {
    dispatch(fetchFaq());
  }, [dispatch]);

  return (
    <>
      {
        <>
          <Banner>{title}</Banner>

          <Content>
            <ContentLayoutSidebar>
              <div>
                {content.map((c: FaqInterface) => (
                  <Details key={c.title} title={c.title} content={parse(c.content)} />
                ))}
              </div>
              <ContentSidebar>
                <BookNow />

                <ContentSidebarItem>
                  <OpeningHours />
                </ContentSidebarItem>
              </ContentSidebar>
            </ContentLayoutSidebar>
          </Content>
        </>
      }
    </>
  );
};

export default FAQ;
