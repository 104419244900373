import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
  padding: var(--space-md) var(--space-lg);
  border-radius: 100px;
  display: inline-block;
  font-family: var(--font-heading);
  background: var(--color-pink);
  text-align: center;

  span {
    font-family: inherit;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
`;
