import { useEffect, useState, useCallback, memo } from 'react';

import NavigationDesktop from 'components/navigation-desktop';
import NavigationMobile from 'components/navigation-mobile';

import { NavigationProps } from './constants';

import { Wrapper, BurgerMenu } from './style';

const Navigation = ({ isMenuOpen, setIsMenuOpen, isTreatment, isTraining }: NavigationProps) => {
  const [isDesktop, setIsDesktop] = useState(false);

  const handleResize = useCallback(() => {
    setIsDesktop(window.innerWidth >= 768);
  }, []);

  const showMobileMenuButton = !isDesktop;
  const showMobileMenu = showMobileMenuButton && isMenuOpen;

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (isDesktop && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isDesktop, isMenuOpen, setIsMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isMenuOpen]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setIsMenuOpen]);

  return (
    <Wrapper>
      {isDesktop && <NavigationDesktop />}

      {showMobileMenuButton && (
        <div onClick={() => toggleMenu()}>
          <BurgerMenu />
        </div>
      )}

      {showMobileMenu && (
        <NavigationMobile
          isTreatment={isTreatment}
          isTraining={isTraining}
          closeMenu={setIsMenuOpen}
        />
      )}
    </Wrapper>
  );
};

export default memo(Navigation);
