import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`


  :root{
    --color-white: #FFFCFF;
    --color-white-alt: #fcfcfc;
    --color-black: #000000;

    --color-light-grey: #c3c2c9;
    --color-dark-grey: #50514f;
    --brand-gradient: linear-gradient(
      137deg,
      var(--color-white) 0%,
      var(--color-light-grey) 100%
  );
    --color-pink: #FEC8D8;
  
    --space-xs: 4px;
    --space-sm: 8px;
    --space-md: 16px;
    --space-lg: 32px;
    --space-xl: 48px;
    --space-xxl: 64px;

    --max-width-mobile: 800px;
    --max-width-desktop: 1040px;
  }


  *{
    font-family: var(--font-body);
  }
  

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
  {
    font-family: var(--font-heading);
  }
  h1{
    font-size: 2em;
  }

  h2{
    font-size: 1.4em;
  }

  h3{
    font-size: 1.2em;
  }

  h4{
    font-size: 1em;
  }

  h5{
    font-size: 0.8em;
  }

  h6{
    font-size: 0.6em;
  }


  .text-center{
    text-align: center;
  }

`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const Main = styled.main`
  flex: 1;
  overflow: hidden;
`;
