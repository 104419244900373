import { BannerProps } from './constants';
import { Wrapper, Inner, Title } from './style';

const Banner = ({ children }: BannerProps) => {
  return (
    <Wrapper>
      <Inner>
        <Title>{children}</Title>
      </Inner>
    </Wrapper>
  );
};

export default Banner;
