import styled from 'styled-components';

export const TableBackground = styled.div`
  background-color: var(--color-white-alt);
  border-radius: 4px;
  padding: var(--space-md);
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableTitle = styled.h2``;
export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  border-bottom: 1px dashed var(--color-light-grey);

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  + h3 {
    margin-top: var(--space-sm);
  }
`;

interface TableContentProps {
  isItalic?: boolean;
}

export const TableContent = styled.div<TableContentProps>`
  &:last-child {
    text-align: right;
  }

  ${({ isItalic }) =>
    isItalic &&
    `text-align: left;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;

    &:only-child {
      text-align: left;
    }

  `}
`;
