import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import EmbeddedEntryInline from 'components/embedded-entry-inline';
import EmbeddedEntryBlock from 'components/embedded-entry-block';

import { ImageWrapper } from 'components/content/style';

const options = {
  renderNode: {
    'embedded-entry-block': (node: any) => {
      const { fields, sys } = node.data.target;

      const isPage = sys?.contentType?.sys?.id === 'page';

      if (isPage && fields) {
        const { title, slug, id, hero } = fields;

        let imgSrc = hero && hero[0]?.fields?.file?.url;

        return (
          <EmbeddedEntryBlock
            key={`embedded-entry-block-${id}`}
            slug={slug}
            title={title}
            img={imgSrc}
          />
        );
      }

      return null;
    },
    'embedded-entry-inline': (node: any) => {
      const { fields, sys } = node?.data?.target;

      const isPage = sys && sys?.contentType?.sys?.id === 'page';

      if (isPage && fields) {
        const { title, slug, id } = fields;

        return (
          <EmbeddedEntryInline key={`embedded-entry-inline-${id}`} slug={slug} title={title} />
        );
      }

      return null;
    },

    'embedded-asset-block': (node: any) => {
      const { fields } = node?.data?.target;

      if (fields) {
        const { file, title, id } = fields;

        const imgSrc = file?.url;

        return (
          <ImageWrapper>
            <img src={imgSrc} key={`embedded-asset-block-${id}`} alt={title} />
          </ImageWrapper>
        );
      }

      return null;
    },
  },
};

const useRichText = (content: any) => documentToReactComponents(content, options);

export default useRichText;
