import styled from 'styled-components';

import { devices } from 'utils/media-query';

import { TableBackground } from 'components/table/style';

export const Wrapper = styled.section`
  * {
    line-height: 1.5;
  }

  h2 {
    display: inline-block;
    margin-bottom: var(--space-sm);
    margin-top: var(--space-md);
    border-bottom: 1px solid var(--color-black);

    @media ${devices.laptop} {
      margin-bottom: var(--space-md);
      margin-top: var(--space-lg);
    }
  }

  p {
    margin-bottom: var(--space-sm);
  }

  ul,
  ol {
    margin-left: var(--space-lg);
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
    list-style-type: initial;

    li {
      margin-bottom: var(--space-sm);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  b,
  strong {
    font-weight: bold;
  }
`;

export const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: var(--max-width-mobile);
  padding: var(--space-lg) var(--space-md);

  @media ${devices.mobileM} {
    padding: var(--space-lg) var(--space-lg);
  }
  @media ${devices.laptop} {
    max-width: var(--max-width-desktop);
    padding: var(--space-lg) var(--space-xxl);
  }
`;

export const ContentLayoutSidebar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-sm);

  @media ${devices.tablet} {
    grid-template-columns: 1fr 300px;
    gap: var(--space-md);
  }
  @media ${devices.laptop} {
    grid-template-columns: 1fr 400px;
    gap: var(--space-lg);
  }
`;

export const ContentSidebar = styled.div`
  display: grid;
  align-content: flex-start;
  grid-template-columns: 1fr;
  align-items: flex-start;
  grid-template-rows: auto;
  gap: var(--space-md);

  @media ${devices.tablet} {
    gap: var(--space-lg);
  }
  @media ${devices.laptop} {
    gap: var(--space-xl);
  }
`;

export const ContentSidebarItem = styled.div`
  background-color: var(--color-white-alt);
  border-radius: 4px;
  padding: var(--space-md);
  border: 1px solid rgba(0, 0, 0, 0.05);

  h2 {
    margin-top: 0;
  }

  ${TableBackground} {
    border: none;
    padding: 0;
  }
`;

export const ImageWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: var(--space-md) 0;

  @media ${devices.tablet} {
    padding: 0 0 var(--space-md);
  }

  @media ${devices.desktop} {
    padding: 0 0 var(--space-lg);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const ContentColumn = styled.div`
  /* img {
    display: block;
    max-width: 300px;
  } */
`;
