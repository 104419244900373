import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AppReducer from './features/appSlice';
import PagesReducer from './features/pagesSlice';
import FaqReducer from './features/faqSlice';

const store = configureStore({
  reducer: {
    app: AppReducer,
    pages: PagesReducer,
    faq: FaqReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
