import { Table, TableTitle, TableRow, TableContent } from 'components/table/style';

interface List {
  time: string;
  price: string;
  notes?: string;
}

interface PriceTableProps {
  list: List[];
}

const PriceTable = ({ list }: PriceTableProps) => (
  <Table>
    <TableTitle>Prices</TableTitle>
    {list.map((l, index) => {
      const { time, price, notes } = l;
      return (
        <TableRow key={index}>
          {time && <TableContent>{time} minutes</TableContent>}
          {price && <TableContent>£{price}</TableContent>}
          {notes && <TableContent isItalic>{notes}</TableContent>}
        </TableRow>
      );
    })}
  </Table>
);

export default PriceTable;
