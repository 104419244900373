import { FullScreenWrapper, FullScreenInner, FullScreenTextWrapper, Title } from './style';

const NotFound = () => {
  return (
    <>
      <FullScreenWrapper>
        <FullScreenInner>
          <FullScreenTextWrapper>
            <Title>Page not found</Title>
          </FullScreenTextWrapper>
        </FullScreenInner>
      </FullScreenWrapper>
    </>
  );
};

export default NotFound;
