import styled from 'styled-components';

import { devices } from 'utils/media-query';

export const Grid = styled.div`
  display: grid;
  /* grid-template-columns: 1fr; */
  gap: var(--space-sm);
  grid-template-columns: 1fr 1fr;

  grid-auto-rows: minmax(200px, auto);

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(300px, auto);
    gap: var(--space-md);
  }
  @media ${devices.laptop} {
    gap: var(--space-lg);
  }
`;
