import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { fetchPageByTag } from 'redux/features/pagesSlice';
import { filterPageBySlug } from 'redux/selectors/page';

import Banner from 'components/banner';
import Content from 'components/content';
import ProductGrid from 'components/product-grid';
import ProductGridItem from 'components/product-grid-item';

const Training = () => {
  const dispatch = useDispatch<AppDispatch>();

  const TRAINING = 'training';

  const getPages = useSelector(filterPageBySlug(TRAINING));

  useEffect(() => {
    dispatch(fetchPageByTag(TRAINING));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner>Training</Banner>
      <Content>
        <ProductGrid>
          {getPages &&
            getPages.map((page: any) => {
              const { hero } = page;

              const backgroundImg = hero && hero[0]?.fields?.file?.url;

              return (
                <ProductGridItem
                  key={page.id}
                  to={page.slug}
                  imgSrc={backgroundImg}
                  imgAlt={page.title}
                >
                  {page.title}
                </ProductGridItem>
              );
            })}
        </ProductGrid>
      </Content>
    </>
  );
};

export default Training;
