import styled from 'styled-components';

import { devices } from 'utils/media-query';
import { ReactComponent as BurgerMenuDefault } from './svg/burger-menu.svg';

export const Wrapper = styled.nav`
  display: flex;
  gap: var(--space-md);
  align-items: center;

  @media ${devices.tablet} {
    gap: var(--space-lg);
  }

  @media ${devices.laptop} {
    gap: var(--space-xl);
  }
`;

export const BurgerMenu = styled(BurgerMenuDefault)`
  color: inherit;
`;
