import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { devices } from 'utils/media-query';

export const GridItem = styled(Link)`
  background: var(--color-dark-grey);
  position: relative;
  grid-column: span 2;
  @media ${devices.tablet} {
    grid-column: span 1;
  }

  &:nth-child(8n + 1) {
    @media ${devices.tablet} {
      grid-row: span 2;
    }
  }
  &:nth-child(8n + 4) {
    @media ${devices.tablet} {
      grid-column: span 2;
    }
  }
  &:nth-child(8n + 7) {
    @media ${devices.tablet} {
      grid-row: span 2;
    }
  }
  &:nth-child(8n + 8) {
    @media ${devices.tablet} {
      grid-column: span 2;
    }
  }
`;

export const GridItemImg = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export const GridItemOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
`;

export const GridItemContent = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-sm);

  @media ${devices.tablet} {
    padding: var(--space-md);
  }
  @media ${devices.laptop} {
    padding: var(--space-lg);
  }
`;

export const GridItemContentTitle = styled.h2`
  font-family: var(--font-heading);
  margin: 0 !important;
  border-top: 1px solid var(--color-black);
`;
