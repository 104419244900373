enum TEXT {
  COMPANY = 'Graceful Aura',
  COMPANY_ABBR = 'Graceful Aura',
  COMPANY_TAG = 'Spa Treatments',
  TREATMENTS = 'Spa Treatments',
  TRAINING = 'Training Academy',
  PHONE = 'tel:07305344246',
  EMAIL = 'mailto:gracefulauramobilespa@gmail.com',
  EMAIL_TRAINING = 'mailto:gracefulauratrainingacademy@gmail.com',
  INSTAGRAM = 'https://www.instagram.com/graceful_aura/',
  INSTAGRAM_CAELIE = 'https://www.instagram.com/graceful_aura_caelie/',
  FACEBOOK = 'https://www.facebook.com/gracefulaura/',
  FRESHA = 'https://www.fresha.com/a/graceful-aura-clinic-woolton-village-liverpool-7-woolton-street-mww7yx9y',
}

export default TEXT;
