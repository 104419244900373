export const getPages = (state: any) => state.pages;

export const filterPageById = (id: string | undefined) => (state: any) => {
  const objects = getPages(state).pages;

  return objects.find((obj: any) => obj.id === id);
};

export const filterPageBySlug = (id: string | undefined) => (state: any) => {
  const objects = getPages(state).pages;

  return objects.filter((obj: any) => obj.slug.includes(id));
};
